import { Theme } from '@mui/material/styles';
import { ThemeMode } from '@ui/useThemeStore';

export const getSpacing =
  (size: number) =>
  ({ theme }: { theme: Theme }) => {
    return theme.spacing(size);
  };

export const isDarkMode = (theme: Theme) => theme?.palette?.mode === ThemeMode.DARK;

export const getColorBasedOnTheme =
  (lightColor: string, darkColor: string) =>
  ({ theme }: { theme: Theme }): string =>
    !lightColor || !darkColor
      ? (theme.palette.background as unknown as string)
      : isDarkMode(theme)
      ? darkColor
      : lightColor;

export const createGradientBackground = (
  camelCase = true,
  withBackgroundAttachment = true,
  topColor = 'rgba(245, 245, 245, 0)',
  bottomColor = 'rgba(10, 10, 10, 0.12)',
  radialTopColor = 'rgba(10, 10, 10, 0.2)',
  radialBottomColor = 'rgba(0, 0, 0, 0)',
) => {
  const linearGradient = `linear-gradient(${topColor}, ${bottomColor} 70%) 0 100%`;
  const radialGradient = `radial-gradient(farthest-side at 50% 100%, ${radialTopColor}, ${radialBottomColor}) 0 100%`;

  const backgroundRepeat = 'no-repeat';
  const backgroundAttachment = 'fixed';
  const backgroundSize = '100% 20px, 100% 20px, 100% 6px, 100% 6px';

  return camelCase
    ? {
        background: `${linearGradient}, ${radialGradient}`,
        backgroundSize,
        backgroundRepeat,
        ...(withBackgroundAttachment && { backgroundAttachment }),
      }
    : {
        background: `${linearGradient}, ${radialGradient}`,
        'background-size': backgroundSize,
        'background-repeat': backgroundRepeat,
        ...(withBackgroundAttachment && { 'background-attachment': backgroundAttachment }),
      };
};

export const scrollableBackgroundShadowStyleObject = createGradientBackground();
export const scrollableBackgroundShadowStyleWithoutBackgroundAttachmentObject =
  createGradientBackground(true, false);
export const scrollableBackgroundShadowStyleObjectDashCase = createGradientBackground(false);

export const scrollableBackgroundShadowStyle =
  Object.entries(scrollableBackgroundShadowStyleObjectDashCase)
    .map(([key, value]) => `${key}: ${value}`)
    .join(';') + ';';
