import { css } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ThemeProvider as MUIThemeProvider, ThemeOptions, createTheme } from '@mui/material/styles';
import { ThemeMode, useThemeStore } from '@ui/useThemeStore';
import { BREAKPOINTS } from '@wyden/constants';
import { ReactNode } from 'react';
import { color } from './theme/colors';
import {
  scrollableBackgroundShadowStyleObject,
  scrollableBackgroundShadowStyleWithoutBackgroundAttachmentObject,
} from '@wyden/utils/styles';

const themeOptions: ThemeOptions = {
  spacing: 4,
  breakpoints: {
    values: BREAKPOINTS,
  },
  typography: {
    h1: {
      fontSize: '1rem',
    },
    h2: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (theme) => css`
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          border-radius: 20px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: ${color[theme.palette.mode].fillsElementsFillWeak};
          border-radius: 20px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: ${color[theme.palette.mode].fillsElementsFillTertiary};
          border-radius: 20px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: ${color[theme.palette.mode].fillsElementsFillTertiary};
        }
        @keyframes skeleton-loading {
          0% {
            background-color: ${color[theme.palette.mode].fillsSurfaceSurfaceTertiary};
          }
          100% {
            background-color: ${color[theme.palette.mode].borderElementsBorderWeak};
          }
        }
        #container {
          height: 100%;
          background-color: ${color[theme.palette.mode].fillsSurfaceSurfacePrimary};
        }
        #container .ag-center-cols-viewport {
          overflow-x: inherit;
        }
        div[row-id='loading-order'] {
          visibility: hidden;
          padding: 4px;
          background-color: ${color[theme.palette.mode].textSemanticTextErrorPrimary} !important;
          ::after {
            content: '';
            width: 100%;
            display: flex;
            align-items: center;
            height: 100%;
            animation: skeleton-loading 1s infinite alternate;
            visibility: visible;
          }
        }
        .ag-row-even.ag-row-level-1 {
          background: ${theme.palette.mode === ThemeMode.LIGHT
            ? grey[100]
            : color['dark'].fillsSurfaceSurfaceSecondary};
        }
        .ag-row-odd.ag-row-level-1 {
          background: ${theme.palette.mode === ThemeMode.LIGHT
            ? grey[200]
            : color['dark'].fillsSurfaceSurfaceSecondary};
        }
        body {
          height: 100%;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
            'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          --nest-background-color: transparent;
        }
        code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
        }
        a {
          color: inherit;
        }
        ul,
        li {
          list-style: none;
          margin: 0;
          padding: 0;
        }
        html,
        #root {
          height: 100%;
          font-family: 'Inter', sans-serif;
        }

        .ag-body-viewport {
          background-color: ${theme.palette.mode === ThemeMode.LIGHT
            ? color['light'].fillsSurfaceSurfaceSecondary
            : color['dark'].fillsSurfaceSurfaceSecondary};
        }

        .ag-body-horizontal-scroll-viewport {
          height: 8px !important;
          min-height: 8px !important;
          max-height: 8px !important;
        }

        .ag-details-row {
          font-size: 11px !important;
        }

        .ag-root-wrapper {
          border: none !important;
          font-size: 11px !important;
          font-family: 'Inter', sans-serif;
        }

        .ag-row {
          font-size: 11px !important;
          color: ${color[theme.palette.mode].textElementsTextSecondary} !important;
        }

        .ag-cell-wrapper {
          height: 100%;
        }

        .ag-row-even {
          background-color: ${color[theme.palette.mode].fillsSurfaceSurfaceSecondary} !important;
        }

        .ag-row-odd {
          background-color: ${color[theme.palette.mode].fillsSurfaceSurfaceTertiary} !important;
        }

        .ag-header {
          border-color: transparent !important;
        }

        .ag-header-cell {
          background-color: ${color[theme.palette.mode].fillsSurfaceSurfacePrimary};
          border-bottom: none;
        }

        .ag-header {
          height: 32px !important;
          min-height: 32px !important;
          .ag-header-cell-text {
            text-overflow: clip;
          }
          .ag-header-cell-resize {
            visibility: hidden;
          }
        }

        .ag-header:hover {
          .ag-header-cell-resize {
            visibility: visible;
          }
        }

        .ag-header-row {
          height: 32px !important;
        }

        .ag-header-cell-label {
          font-weight: normal;
          color: ${color[theme.palette.mode].textElementsTextSecondary};
        }

        .ag-sort-indicator-icon.ag-sort-order {
          color: ${color[theme.palette.mode].textElementsTextSecondary};
        }

        .ag-icon-asc {
          background: transparent url('/icons/sort-icon.svg') center/contain no-repeat;
          transform: rotate(180deg);
          background-size: 20px;
          color: transparent;
        }

        .ag-icon-desc {
          background: transparent url('/icons/sort-icon.svg') center/contain no-repeat;
          background-size: 20px;
          color: transparent;
        }

        .ag-horizontal-right-spacer {
          display: none;
        }

        .introjs-tooltip {
          color: black;
          user-select: none;
          min-width: 350px !important;
          max-width: 500px !important;
        }

        .Mui-disabled {
          background-color: ${color[theme.palette.mode].fillsElementsFillInactive};
        }

        .MuiInputBase-input.Mui-disabled {
          background-color: ${color[theme.palette.mode].fillsElementsFillInactive};
          color: ${color[theme.palette.mode].textElementsTextWeak};
          -webkit-text-fill-color: ${color[theme.palette.mode].textElementsTextWeak} !important;
        }

        .MuiInputBase-root.Mui-disabled > fieldset,
        .MuiInputBase-root.Mui-disabled label {
          color: ${color[theme.palette.mode].textElementsTextWeak};
          border: none;
        }

        .MuiFormControl-root {
          margin: 0 !important;
        }
      `,
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: color.dark.fillsElementsFillTertiary,
          color: color.dark.textElementsTextPrimary,
          userSelect: 'none',
        },
        arrow: {
          backgroundColor: 'transparent',
          color: color.dark.fillsElementsFillTertiary,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          // if you change color below, pay attention to Connect Account Modal
          // the point is not to constantly change colors for different modals
          backgroundColor: color[theme.palette.mode].fillsSurfaceSurfaceSecondary,
          backgroundImage: 'unset',

          '&.MuiPickersPopper-paper': {
            backgroundImage: 'unset',
          },
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: 'transparent',
          color: color[theme.palette.mode].textElementsTextPrimary,
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'small',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: color[theme.palette.mode].fillsElementsFillHover,
          color: color[theme.palette.mode].textElementsTextPrimary,
          borderColor: 'transparent',
          '&:hover': {
            backgroundColor: color[theme.palette.mode].fillsElementsFillWeak,
            borderColor: 'transparent',
          },

          '&.MuiButton-text': {
            backgroundColor: 'transparent',
            color: color[theme.palette.mode].textElementsTextSecondary,
          },
          '&.MuiButton-text:hover': {
            color: color[theme.palette.mode].textElementsTextPrimary,
          },

          '&.MuiButton-outlined': {
            backgroundColor: 'transparent',
            color: color[theme.palette.mode].textElementsTextSecondary,
            borderColor: color[theme.palette.mode].borderElementsBorderSecondary,
          },
          '&.MuiButton-outlined:hover': {
            color: color[theme.palette.mode].textElementsTextPrimary,
            borderColor: color[theme.palette.mode].fillsElementsFillWeak,
          },
          '&.MuiButton-outlinedError': {
            color: color[theme.palette.mode].textElementsTextPrimary,
            borderColor: color[theme.palette.mode].borderSemanticBorderError,
          },
          '&.MuiButton-outlinedError:hover': {
            color: color[theme.palette.mode].textElementsTextPrimary,
            borderColor: color[theme.palette.mode].borderSemanticBorderError,
          },
          '&.MuiButton-containedSecondary': {
            backgroundColor: `${color[theme.palette.mode].fillsBrandFillBrandPrimary}`,
            color: color[theme.palette.mode].textElementsTextPrimary,
          },
          '&.MuiButton-containedSecondary:hover': {
            backgroundColor: color[theme.palette.mode].fillsBrandFillBrandSecondary,
          },

          '&.Mui-disabled': {
            backgroundColor: color[theme.palette.mode].fillsElementsFillInactive,
            color: color[theme.palette.mode].textElementsTextInactive,
          },
        }),
      },
    },
    MuiFilledInput: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
      styleOverrides: {
        root: () => ({
          marginLeft: 0,
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          },
        }),
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          fieldset: {
            borderColor: `${color[theme.palette.mode].borderElementsBorderSecondary} !important`,
          },
          ':hover': {
            fieldset: {
              borderColor: `${color[theme.palette.mode].borderElementsBorderPrimary} !important`,
            },
          },
          '&.Mui-error > fieldset': {
            borderColor: `${color[theme.palette.mode].borderSemanticBorderError} !important`,
          },
        }),
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '& label.Mui-focused': {
            color: color[theme.palette.mode].textElementsTextPrimary,
          },
          // focused color for input with variant='standard'
          '& .MuiInput-underline:after': {
            borderColor: color[theme.palette.mode].borderElementsBorderPrimary,
          },
          // focused color for input with variant='filled'
          '& .MuiFilledInput-underline:after': {
            borderColor: color[theme.palette.mode].borderElementsBorderPrimary,
          },
          // focused color for input with variant='outlined'
          '& .MuiOutlinedInput-root': {
            fieldset: {
              borderColor: color[theme.palette.mode].borderElementsBorderSecondary,
            },
            '&.Mui-focused fieldset': {
              borderColor: color[theme.palette.mode].borderElementsBorderPrimary,
            },
          },
        }),
        inputFocused: () => ({
          borderColor: 'blue !important',
        }),
        paper: ({ theme }) => ({
          backgroundColor: color[theme.palette.mode].fillsSurfaceSurfaceTertiary,
        }),
        listbox: ({ theme }) => ({
          backgroundColor: color[theme.palette.mode].fillsSurfaceSurfaceTertiary,
          ...scrollableBackgroundShadowStyleObject,
        }),
        option: ({ theme }) => ({
          ':hover': {
            backgroundColor: color[theme.palette.mode].fillsElementsFillHover,
          },
          '&[aria-selected="true"]': {
            backgroundColor: `${color[theme.palette.mode].fillsElementsFillHover} !important`,
          },
        }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiInputLabel-root': {
            backgroundColor: 'transparent',
            color: color[theme.palette.mode].textElementsTextWeak,
          },
          '.MuiInputLabel-asterisk': {
            display: 'none',
            visibility: 'hidden',
          },
          '&.MuiInputLabel-sizeSmall': {
            fontSize: '11px',
            marginTop: '1px',
          },
          '&.MuiInputLabel-shrink': {
            backgroundColor: color[theme.palette.mode].fillsSurfaceSurfaceSecondary,
            top: 0,
            padding: '0 4px',
            borderRadius: '4px',
            fontSize: 'initial',
            color: color[theme.palette.mode].textElementsTextSecondary,
          },
          '&.MuiInputLabel-shrink.Mui-disabled': {
            color: color[theme.palette.mode].textElementsTextWeak,
          },
        }),
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiFormLabel-root': {
            color: color[theme.palette.mode].textElementsTextWeak,
            fontSize: '11px',
          },
          '&.Mui-error': {
            color: color[theme.palette.mode].fillsSemanticFillError,
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
            color: color[theme.palette.mode].textElementsTextSecondary,
            backgroundColor: 'transparent',
            fontSize: '11px',
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          },
        }),
      },
    },
    MuiListItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: ({ theme }) => ({
          '&[role="menu"]': {
            backgroundColor: color[theme.palette.mode].fillsSurfaceSurfaceTertiary,
          },
          '&[role="listbox"]': {
            ...scrollableBackgroundShadowStyleWithoutBackgroundAttachmentObject,
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: { minHeight: '40px' },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            color: color[theme.palette.mode].textElementsTextPrimary,
          },
          '&.Mui-disabled, &.Mui-selected.Mui-disabled': {
            cursor: 'not-allowed',
            color: color[theme.palette.mode].textElementsTextInactive,
            '&:hover': {
              backgroundColor: 'transparent',
              cursor: 'not-allowed',
            },
          },
        }),
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          ':hover': {
            fieldset: {
              borderColor: `${color[theme.palette.mode].borderElementsBorderPrimary} !important`,
            },
          },
          '& label.Mui-focused': {
            color: color[theme.palette.mode].textElementsTextSecondary,
          },
          // focused color for input with variant='standard'
          '& .MuiInput-underline:after': {
            borderColor: color[theme.palette.mode].borderElementsBorderPrimary,
          },
          // focused color for input with variant='filled'
          '& .MuiFilledInput-underline:after': {
            borderColor: color[theme.palette.mode].borderElementsBorderPrimary,
          },
          // focused color for input with variant='outlined'
          '& .MuiOutlinedInput-root': {
            fieldset: {
              borderColor: color[theme.palette.mode].borderElementsBorderSecondary,
            },
            '&.Mui-focused fieldset': {
              borderColor: color[theme.palette.mode].borderElementsBorderPrimary,
            },
          },
        }),
      },
    },
    MuiToolbar: {
      defaultProps: {
        variant: 'dense',
      },
    },
  },
};

export function ThemeProvider({ children }: { children: ReactNode }) {
  const { mode } = useThemeStore();

  const customTheme = createTheme({
    ...themeOptions,
    palette: {
      mode,
      success: {
        main: color['dark'].textSemanticTextSuccessPrimary,
      },
      error: {
        main: color['light'].textSemanticTextErrorPrimary,
      },
      primary: {
        main:
          mode === 'light'
            ? color['light'].fillsBrandFillBrandPrimary
            : color['dark'].fillsBrandFillBrandPrimary,
      },
      background: {
        default: color['light'].fillsSurfaceSurfacePrimary,
        paper: color['light'].fillsSurfaceSurfaceSecondary,
      },
      ...(mode === 'dark' && {
        background: {
          default: color['dark'].fillsSurfaceSurfacePrimary,
          paper: color['dark'].fillsSurfaceSurfaceSecondary,
        },
      }),
    },
    typography: {
      fontSize: 12,
      fontFamily: 'Inter, sans-serif',
    },
  });

  return (
    <MUIThemeProvider theme={customTheme}>
      {children}
      <CssBaseline />
    </MUIThemeProvider>
  );
}
